

const getBaseUrl = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/rblrally2024/rblrally2024-api/api`;
        return apiUrl;
    }
    else{
        return "";
    }
}

const getTemplate = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/rblrally2024/rblrally2024-api/template_spirit_mas.xlsx`;
        return apiUrl;
    }
    else{
        return "";
    }
}


const appConfig = {
    pathUserTemplate: 'https://rblcpl-api.eventqiu.com/template/template_rbl_cpl_2024.xlsx',
    apiPrefix: 'https://rblcpl-api.eventqiu.com/api',
    mediaPrefix: 'https://rblcpl-api.eventqiu.com/media',
    
    // pathUserTemplate: getTemplate(),
    // apiPrefix: getBaseUrl(),
    
    pathCodeTemplate: '',
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig

